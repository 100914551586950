import { Splide } from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';

let sliderInstances = [];
let mobileOnlySlidersInit = false;


document.addEventListener('DOMContentLoaded', function () {
  bindOutlineAcc();
  bindCheckboxAcc();
  initTooltips();
  bindHashScroll();
  initSliders();
  bindHeaderSlide();
  setTimeout(showModalHolistic, 4500);
})

function showModalHolistic() {
  const checkPage = () => {
    const url = window.location.href;
    return !url.includes("curso-holistic-testing") &&
      !url.includes("evaluacion-madurez-testing/check");
  }
  if (checkPage() &&
    !window.sessionStorage.getItem("shownModalHolistic")) {
    window.sessionStorage.setItem("shownModalHolistic", true);
    const modal = $("#modal-holistic");
    if (modal.length) {
      modal.modal("show");
    }
  }
}

function bindHeaderSlide() {
  $(document).on("click", ".slide-down > .icon", () => {
    const target = $("section:nth-of-type(2)");
    if (target.length)
      scrollToAnchor(target, undefined, { offsetTop: -50 });
  })
}

function bindCheckboxAcc() {
  $(document).on("keypress", "label[for]", function (e) {
    if ((e.keyCode ? e.keyCode : e.which) == 13) {
      const forElem = $("#" + $(this).attr("for"));
      if (forElem[0].tagName.toLowerCase() == "input" && forElem[0].type.toLowerCase() == "checkbox")
        $(this).trigger('click');
    }
  });
}

function bindOutlineAcc() {
  // Listen to tab events to enable outlines (accessibility improvement)
  document.body.addEventListener('keyup', function (e) {
    const targetTag = e.target.tagName.toLowerCase();
    if (e.which === 9 && !["input", "textarea"].includes(targetTag)) /* tab */ {
      document.body.classList.add('enable-outline');
    }
  });
}

function initTooltips() {
  $('[data-toggle="tooltip"]').tooltip();
}

function bindHashScroll() {
  $("a[href*='#']:not([href='#'])").click(function (e) {
    let hash = this.hash;
    let target = $(hash);
    if ($(hash).length > 0) {
      e.preventDefault();
      if (hash) {
        scrollToAnchor(target, undefined, { offsetTop: -50 });
      }
      window.location.hash = hash;
    }
  });
}

function initSliders() {
  const sliders = document.querySelectorAll('.splide:not(.splide-disable-desktop)');
  sliders.forEach(elem => {
    sliderInstances.push(mountSlider(elem));
  })
  toggleMobileOnlySliders();
  window.addEventListener('resize', function (e) {
    toggleMobileOnlySliders();
  }, { passive: false })
}

function toggleMobileOnlySliders() {
  if (getWidth() <= 768 && !mobileOnlySlidersInit) {
    mobileOnlySlidersInit = true;
    const sliders = document.querySelectorAll('.splide.splide-disable-desktop');
    sliders.forEach(elem => {
      sliderInstances.push(mountSlider(elem));
    })
  }
  else if (getWidth() > 768 && mobileOnlySlidersInit) {
    mobileOnlySlidersInit = false;
    sliderInstances.filter(s => s.root.classList.contains("splide-disable-desktop")).forEach((slider, index) => {
      slider.destroy(true);
      document.querySelectorAll(".splide__slide").forEach(s => {
        s.style.removeProperty("width");
      })
      sliderInstances.splice(index, 1);
    })
  }
}

function mountSlider(elem) {
  let mods = {};
  const splideOptions = $(elem).attr("data-splide");
  if (splideOptions.includes("intersection"))
    mods = { ...mods, Intersection };
  return new Splide(elem).mount(mods);
}

function getWidth() {
  return window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

}
function getHeight() {
  return window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;
}

$(window).mouseup(function () {
  var o = $(window).scrollTop();
  sessionStorage.setItem("scrollTop", o);
});

function scrollToAnchor(target, callback, options = {}) {
  const addOffset = options.offsetTop || 0;
  if (window.closeMobileNav) window.closeMobileNav();
  $('html, body').animate({
    scrollTop: target.offset().top + addOffset
  }, {
    duration: options.duration || 1000,
    // At each animation step, check whether the target has moved.
    // In case lazy of lazy loaded content finished loading.
    step: function (now, fx) {
      var newOffset = target.offset().top + addOffset;
      if (fx.end !== newOffset)
        fx.end = newOffset;
    }
  }, 'swing', callback);
}
